<template>
  <div>
    <h4>{{ $t("sn-item-location.control-title") }}</h4>
    <b-overlay :show="isLoading">
      <b-row>
        <b-col>
          <v-select
            v-if="location"
            :value="currentLocation"
            :disabled="isDecommissioned"
            @input="onChangeLocation"
            :options="availableLocations"
            label="registrationNumber"
            :clearable="false"
          >
            <span slot="no-options">{{ $t("sn-item-location.no-options-text") }}</span>
          </v-select>
        </b-col>
        <b-col>
          <b-row>
            <b-button
              class="mr-2"
              v-if="!isInWarehouse && !isDecommissioned"
              type="button"
              @click="onMoveToWarehouseClick"
            >
              {{ $t("sn-item-location.move-to-warehouse-btn-caption") }}
            </b-button>
            <b-button v-if="!isDecommissioned" v-b-modal.decommission-confirmation-modal>
              {{ $t("sn-item-location.decommission-btn-caption") }}
            </b-button>
            <b-modal
              id="decommission-confirmation-modal"
              @ok.prevent="onDecommissionClick"
              @show="resetDecommissionForm"
              size="lg"
              :title="$t('sn-item-location.decommission-reason-modal.title')"
              :ok-title="$t('sn-item-location.decommission-reason-modal.ok-btn-caption')"
              :cancel-title="$t('sn-item-location.decommission-reason-modal.cancel-btn-caption')"
              :ok-disabled="!isDecommissionReasonValid"
            >
              <template>
                <b-overlay :show="isDecommissioning">
                  <b-form-textarea
                    v-model="decommissionReason"
                    :placeholder="$t('sn-item-location.decommission-reason-modal.reason-placeholder')"
                    :state="isDecommissionReasonValid"
                  ></b-form-textarea>
                </b-overlay>
              </template>
            </b-modal>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import api from "@/services/api.js";
import { VEHICLE, DECOMMISSIONED, WAREHOUSE } from "@/const/load-item-locations.js";

export default {
  props: {
    serialNumberItemId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isDecommissioning: false,
      location: null,
      availableLocations: [],
      vehicleId: null,
      decommissionReason: ""
    };
  },
  computed: {
    currentLocation() {
      if (!this.location) return "";
      if (this.location == VEHICLE) {
        return this.availableLocations.find(x => x.id == this.vehicleId);
      } else {
        return this.$t("sn-item-location.location." + this.location);
      }
    },
    isDecommissioned() {
      return this.location == DECOMMISSIONED;
    },
    isInWarehouse() {
      return this.location == WAREHOUSE;
    },

    isDecommissionReasonValid() {
      return this.decommissionReason !== "";
    }
  },
  methods: {
    loadData() {
      this.isLoading = true;
      let currentLocationRequest = api
        .getSerialNumberItemLocation(this.serialNumberItemId)
        .then(resp => {
          this.location = resp.data.location;
          this.vehicleId = resp.data.vehicleId;
        })
        .catch(() => alert(this.$t("sn-item-location.error.current-location-loading")));

      let availableLocationsRequest = api
        .getAvailableVehicles()
        .then(resp => (this.availableLocations = resp.data))
        .catch(() => alert(this.$t("sn-item-location.error.available-locations-loading")));

      Promise.all([currentLocationRequest, availableLocationsRequest]).finally(() => (this.isLoading = false));
    },
    onChangeLocation(newLocation) {
      this.isLoading = true;
      api
        .moveSerialNumberItemToVehicle(this.serialNumberItemId, newLocation.id)
        .then(() => {
          this.$emit("location-changed");
          this.loadData();
        })
        .catch(() => alert(this.$t("sn-item-location.error.moving-to-vehicle")))
        .finally(() => (this.isLoading = false));
    },
    onMoveToWarehouseClick() {
      this.isLoading = true;
      api
        .moveSerialNumberItemToWarehouse(this.serialNumberItemId)
        .then(() => {
          this.$emit("location-changed");
          this.loadData();
        })
        .catch(() => alert(this.$t("sn-item-location.error.moving-to-warehouse")))
        .finally(() => (this.isLoading = false));
    },
    onDecommissionClick() {
      this.isDecommissioning = true;
      api
        .decommissionLoadItem(this.serialNumberItemId, this.decommissionReason)
        .then(() => {
          this.$emit("location-changed");
          this.$bvModal.hide("decommission-confirmation-modal");
          this.loadData();
        })
        .catch(() => alert(this.$t("sn-item-location.error.decommissioning")))
        .finally(() => (this.isDecommissioning = false));
    },
    resetDecommissionForm() {
      this.decommissionReason = "";
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    serialNumberItemId() {
      this.loadData();
    }
  }
};
</script>
