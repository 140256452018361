<template>
  <b-container fluid>
    <serial-number-item-info :serialNumberItemId="serialNumberItemId"></serial-number-item-info>
    <serial-number-item-location
      :serialNumberItemId="serialNumberItemId"
      @location-changed="onLocationChanged"
    ></serial-number-item-location>
    <serial-number-item-status
      :serialNumberItemId="serialNumberItemId"
      @status-changed="onStatusChanged"
    ></serial-number-item-status>
    <serial-number-item-history
      ref="serialNumberItemHistory"
      :serialNumberItemId="serialNumberItemId"
    ></serial-number-item-history>
  </b-container>
</template>

<script>
import SerialNumberItemHistory from "@/components/serial-number-item-history/SerialNumberItemHistory.vue";
import SerialNumberItemInfo from "@/components/serial-number-item-history/SerialNumberItemInfo.vue";
import SerialNumberItemLocation from "@/components/serial-number-item-history/SerialNumberItemLocation.vue";
import SerialNumberItemStatus from "@/components/serial-number-item-history/SerialNumberItemStatus.vue";

export default {
  components: {
    SerialNumberItemHistory,
    SerialNumberItemInfo,
    SerialNumberItemLocation,
    SerialNumberItemStatus
  },
  computed: {
    serialNumberItemId() {
      return Number(this.$route.params.serialNumberItemId);
    }
  },
  methods: {
    onLocationChanged() {
      this.$refs.serialNumberItemHistory.loadData();
    },
    onStatusChanged() {
      this.$refs.serialNumberItemHistory.loadData();
    }
  }
};
</script>
