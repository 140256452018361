<template>
  <div>
    <h4>{{ $t("sn-item-status.control-title") }}</h4>
    <b-overlay :show="isLoading">
      <b-row>
        <b-col>
          <b-form-select :value="status" :options="statuses" @change="onStatusChanged"></b-form-select>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import api from "@/services/api.js";
import { ACTIVE, LOST, BROKEN } from "@/const/sn-item-statuses";

export default {
  props: {
    serialNumberItemId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      status: ""
    };
  },
  computed: {
    statuses() {
      return [
        { value: ACTIVE, text: this.$t("sn-item-status.statuses.active") },
        { value: LOST, text: this.$t("sn-item-status.statuses.lost") },
        { value: BROKEN, text: this.$t("sn-item-status.statuses.broken") }
      ];
    }
  },
  methods: {
    loadData() {
      this.isLoading = true;
      api
        .getSerialNumberItemStatus(this.serialNumberItemId)
        .then(resp => {
          if (resp.data.isLost) {
            this.status = LOST;
          } else if (resp.data.isBroken) {
            this.status = BROKEN;
          } else {
            this.status = ACTIVE;
          }
        })
        .catch(() => alert(this.$t("sn-item-status.error.while-loading-current-status")))
        .finally(() => (this.isLoading = false));
    },
    onStatusChanged(evt) {
      let vm = {
        id: this.serialNumberItemId,
        isBroken: evt === BROKEN,
        isLost: evt === LOST
      };
      this.isLoading = true;
      api
        .setSerialNumberItemStatus(vm)
        .then(() => {
          this.$emit("status-changed");
        })
        .catch(() => alert(this.$t("sn-item-location.error.while-setting-status")))
        .finally(() => this.loadData());
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    serialNumberItemId() {
      this.loadData();
    }
  }
};
</script>
