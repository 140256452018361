<template>
  <div>
    <h4>{{ $t("sn-item-history.control-title") }}</h4>
    <b-overlay :show="isLoading">
      <ul v-if="historyItems.length > 0">
        <li v-for="historyItem in historyItems" :key="historyItem.id">
          [{{ $d(Date.parse(historyItem.createdAt), "log") }}]
          <span v-if="isAdded(historyItem.type)">
            {{
              $t("sn-item-history.event.added", {
                user: historyItem.userName
              })
            }}
          </span>
          <span v-else-if="isMovedToWarehouse(historyItem.type)">
            {{ $t("sn-item-history.event.moved-to-warehouse", { user: historyItem.userName }) }}
          </span>
          <span v-else-if="isMovedToVehicle(historyItem.type)">
            {{
              $t("sn-item-history.event.moved-to-vehicle", {
                user: historyItem.userName,
                vehicle: historyItem.vehicleRegistrationNumber
              })
            }}
          </span>
          <span v-else-if="isSetLost(historyItem.type)">
            {{ $t("sn-item-history.event.set-lost", { user: historyItem.userName }) }}
          </span>
          <span v-else-if="isSetBroken(historyItem.type)">
            {{ $t("sn-item-history.event.set-broken", { user: historyItem.userName }) }}
          </span>
          <span v-else-if="isUnsetLost(historyItem.type)">
            {{ $t("sn-item-history.event.unset-lost", { user: historyItem.userName }) }}
          </span>
          <span v-else-if="isUnsetBroken(historyItem.type)">
            {{ $t("sn-item-history.event.unset-broken", { user: historyItem.userName }) }}
          </span>
          <span v-else-if="isDecommissioned(historyItem.type)">
            {{
              $t("sn-item-history.event.decommissioned", {
                user: historyItem.userName,
                reason: historyItem.decommissionReason
              })
            }}
          </span>
        </li>
      </ul>
    </b-overlay>
  </div>
</template>

<script>
import api from "@/services/api.js";
import {
  ADDED,
  MOVED_TO_WAREHOUSE,
  MOVED_TO_VEHICLE,
  SET_LOST,
  SET_BROKEN,
  UNSET_LOST,
  UNSET_BROKEN,
  DECOMMISSION
} from "@/const/sn-item-history-event-types.js";

export default {
  props: {
    serialNumberItemId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      historyItems: []
    };
  },
  methods: {
    isAdded(type) {
      return type === ADDED;
    },
    isMovedToWarehouse(type) {
      return type === MOVED_TO_WAREHOUSE;
    },
    isMovedToVehicle(type) {
      return type === MOVED_TO_VEHICLE;
    },
    isSetLost(type) {
      return type === SET_LOST;
    },
    isSetBroken(type) {
      return type === SET_BROKEN;
    },
    isUnsetLost(type) {
      return type === UNSET_LOST;
    },
    isUnsetBroken(type) {
      return type === UNSET_BROKEN;
    },
    isDecommissioned(type) {
      return type === DECOMMISSION;
    },
    loadData() {
      this.isLoading = true;
      api
        .getSerialNumberItemHistory(this.serialNumberItemId)
        .then(resp => (this.historyItems = resp.data))
        .catch(() => alert(this.$t("sn-item-history.general-error-message")))
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    serialNumberItemId() {
      this.loadData();
    }
  }
};
</script>
