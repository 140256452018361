<template>
  <div>
    <h4>{{ $t("sn-item-info.control-title") }}</h4>
    <b-overlay :show="isLoading">
      <table v-if="serialNumberItemInfo" class="table">
        <tr>
          <td>{{ $t("sn-item-info.title") }}</td>
          <td>{{ serialNumberItemInfo.title[$i18n.locale] }}</td>
        </tr>
        <tr>
          <td>{{ $t("sn-item-info.code") }}</td>
          <td>{{ serialNumberItemInfo.code }}</td>
        </tr>
        <tr>
          <td>{{ $t("sn-item-info.serial-number") }}</td>
          <td>{{ serialNumberItemInfo.serialNumber }}</td>
        </tr>
        <tr>
          <td>{{ $t("sn-item-info.created-at") }}</td>
          <td>{{ $d(Date.parse(serialNumberItemInfo.createdAt)) }}</td>
        </tr>
        <tr>
          <td>{{ $t("sn-item-info.created-by") }}</td>
          <td>{{ serialNumberItemInfo.createdBy }}</td>
        </tr>
        <tr>
          <td>{{ $t("sn-item-info.groups") }}</td>
          <td>{{ serialNumberItemInfo.groups.map(x => x[$i18n.locale]).join(";") }}</td>
        </tr>
        <tr v-if="serialNumberItemInfo.imageId">
          <td>{{ $t("sn-item-info.image") }}</td>
          <td><thumbnail-image :imageId="serialNumberItemInfo.imageId" /></td>
        </tr>
      </table>

      <image-modal />
    </b-overlay>
  </div>
</template>

<script>
import api from "@/services/api.js";
import ImageModal from "@/components/goods-images/ImageModal.vue";
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";

export default {
  props: {
    serialNumberItemId: {
      type: Number,
      required: true
    }
  },
  components: {
    "image-modal": ImageModal,
    "thumbnail-image": ThumbnailImage
  },
  data() {
    return {
      isLoading: false,
      serialNumberItemInfo: null
    };
  },
  methods: {
    loadData() {
      this.isLoading = true;
      api
        .getSerialNumberItemInfo(this.serialNumberItemId)
        .then(resp => (this.serialNumberItemInfo = resp.data))
        .catch(() => alert(this.$t("sn-item-info.general-error")))
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    serialNumberItemId() {
      this.loadData();
    }
  }
};
</script>
